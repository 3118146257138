import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { dashboardDark, dashboardLight } from '../images';

import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: '6px solid',
  outlineColor: 'hsla(220, 25%, 80%, 0.2)',
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundImage: `url(${dashboardLight})`,
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    backgroundImage: `url(${dashboardDark})`,
    outlineColor: 'hsla(220, 20%, 42%, 0.1)',
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        {/* <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'row', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
              fontFamily: '"Poppins", sans-serif',
            }}
          >
            Somos&nbsp;
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' }, // Column for small screens, row for larger screens
              alignItems: 'center', // Center alignment for flex items
              justifyContent: 'center', // Adjust based on your layout needs
              width: '100%', // Ensure the box takes full width
              textAlign: 'center', // Center text for small screens
            }}
          >
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({    
                fontSize: { xs: '24px', sm: '32px', md: '48px' }, // Responsive font size
                color: '#e91e63',
                fontFamily: '"Poppins", sans-serif',
                textAlign: { xs: 'center', sm: 'left' }, // Center text on small screens, left-align on larger
                ...theme.applyStyles('dark', {
                  color: '#e91e63',
                }),
              })}
            >
              Software Factory CR
            </Typography>
          </Box>


          </Typography>
          <Typography
            sx={{
              textAlign: 'justify',
              color: 'text.primary',
              width: { sm: '100%', md: '120%' },
              fontSize: '16px',              
            }}
          >
           En Software Factory CR, nos especializamos en ofrecer soluciones tecnológicas personalizadas y de alta calidad para el desarrollo de software web y móvil, utilizando el potente MERN stack (MongoDB, Express, React y Node.js) como nuestra herramienta principal. Desarrollamos aplicaciones web interactivas con React y aplicaciones móviles nativas y multiplataforma con React Native, brindando rendimiento óptimo y experiencia de usuario superior. Nuestro enfoque se basa en entender a fondo las necesidades de cada cliente para crear soluciones a medida, garantizando agilidad, escalabilidad y seguridad en cada proyecto. Además de desarrollar software innovador, ofrecemos servicios de integración con APIs y consultoría tecnológica para asegurar que su proyecto esté alineado con los objetivos de negocio y la tecnología más avanzada. Con Software Factory CR, transformamos sus ideas en aplicaciones de alto impacto que elevan la competitividad de su empresa en un entorno digital en constante evolución. ¡Contáctenos y llevemos juntos su proyecto al siguiente nivel!
          </Typography>         
         
        </Stack> */}
        <Stack
  spacing={2}
  useFlexGap
  sx={{
    alignItems: 'center',
    width: { xs: '100%', sm: '85%', md: '70%' }, // Responsive width
  }}
>
  <Typography
    variant="h1"
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'column', md: 'row' }, // Column on small/medium screens, row on large
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 'clamp(2.5rem, 8vw, 3.5rem)', // Responsive font size
      fontFamily: '"Poppins", sans-serif',
      textAlign: 'center', // Center text on smaller screens
      width: '100%', // Ensure full width usage
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' }, // Keep column layout
        width: { xs: '100%', sm: '100%', md: 'auto' }, // Full width on small, auto on large
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Typography
        component="span"
        variant="h1"
        sx={{
          fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' }, // Ensure same font size for both words
          color: 'inherit',
          fontFamily: '"Poppins", sans-serif',
          textAlign: 'center', // Center text for all screen sizes
          margin: "10px"
        }}
      >
        Somos
      </Typography>
      <Typography
        component="span"
        variant="h1"
        sx={{
          fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' }, // Same font size as 'Somos'
          color: '#e91e63',
          fontFamily: '"Poppins", sans-serif',
          textAlign: 'center', // Center text for all screen sizes
        }}
      >
         Software Factory CR
      </Typography>
    </Box>
  </Typography>

  <Typography
    sx={{
      textAlign: 'justify',
      color: 'text.primary',
      width: { xs: '100%', sm: '100%', md: '120%' },
      fontSize: { xs: '14px', sm: '16px' }, // Responsive font size
      lineHeight: '1.5', // Improved readability
    }}
  >
    En Software Factory CR, nos especializamos en ofrecer soluciones tecnológicas personalizadas y de alta calidad para el desarrollo de software web y móvil, utilizando el potente MERN stack (MongoDB, Express, React y Node.js) como nuestra herramienta principal. Desarrollamos aplicaciones web interactivas con React y aplicaciones móviles nativas y multiplataforma con React Native, brindando rendimiento óptimo y experiencia de usuario superior. Nuestro enfoque se basa en entender a fondo las necesidades de cada cliente para crear soluciones a medida, garantizando agilidad, escalabilidad y seguridad en cada proyecto. Además de desarrollar software innovador, ofrecemos servicios de integración con APIs y consultoría tecnológica para asegurar que su proyecto esté alineado con los objetivos de negocio y la tecnología más avanzada. Con Software Factory CR, transformamos sus ideas en aplicaciones de alto impacto que elevan la competitividad de su empresa en un entorno digital en constante evolución. ¡Contáctenos y llevemos juntos su proyecto al siguiente nivel!
  </Typography>
</Stack>

        
        <StyledBox id="image" />
      </Container>
    </Box>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Infraestructura en la nube',
    description:
      'Otra opción que ofrecemos es la provisión de infraestructura basada en la nube. Esta solución no solo asegura un alto rendimiento de la aplicación, sino que también garantiza la seguridad y la integridad de los datos. Utilizamos plataformas de nube confiables para escalar los recursos según las necesidades del cliente, permitiendo que nuestras aplicaciones se adapten rápidamente a cambios en la demanda sin comprometer la velocidad ni la seguridad.',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Soluciones On-Premise',
    description:
      'Para aquellos clientes que prefieren un enfoque más tradicional, Software Factory CR también ofrece la opción de soluciones on-premise. Esta alternativa permite a las empresas alojar sus aplicaciones y datos en su propia infraestructura, lo que puede resultar beneficioso para organizaciones que tienen requisitos específicos de seguridad o cumplimiento. Nuestro equipo trabaja de cerca con los clientes para asegurar que la configuración on-premise esté optimizada para el rendimiento, asegurando que cada aplicación funcione de manera fluida y eficiente.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Compromiso con el Rendimiento',
    description:
      'En última instancia, nuestro compromiso con el rendimiento va más allá de la infraestructura. En Software Factory CR, implementamos prácticas de desarrollo eficientes y utilizamos herramientas de monitoreo y optimización para garantizar que nuestras aplicaciones ofrezcan la mejor experiencia posible. Ya sea a través de una infraestructura evaluada, soluciones en la nube o on-premise, nuestro objetivo es siempre maximizar el rendimiento y la satisfacción del cliente.',
  },
  // {
  //   icon: <AutoFixHighRoundedIcon />,
  //   title: 'Innovación',
  //   description:
  //     'Condominio 506 se distingue por su innovación al integrar tecnología avanzada para la gestión integral de condominios, ofreciendo soluciones personalizadas, automatización eficiente y una experiencia de usuario moderna y adaptable.',
  // },
  // {
  //   icon: <SupportAgentRoundedIcon />,
  //   title: 'Soporte',
  //   description:
  //     'Condominio 506 proporciona un soporte integral y proactivo, asegurando asistencia rápida y eficaz para resolver cualquier incidencia, optimizar el uso de la plataforma y garantizar una experiencia continua y sin interrupciones.',
  // },
  // {
  //   icon: <QueryStatsRoundedIcon />,
  //   title: 'Precisión en cada detalle',
  //   description:
  //     'Condominio 506 se destaca por su precisión en cada detalle, asegurando una gestión meticulosa y exacta de cada aspecto del condominio para ofrecer una administración eficiente y sin errores.',
  // },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Infraestructura 
          </Typography>
          <Typography variant="body1" sx={{ color: '#ffffff', textAlign: "justify", fontSize: '16px' }}>
          En Software Factory CR, entendemos que el rendimiento de nuestras aplicaciones depende en gran medida de la infraestructura subyacente. Por eso, ofrecemos un servicio de evaluación integral de los recursos proporcionados por el cliente, incluyendo bases de datos, servidores web, y APIs. Esta evaluación nos permite identificar las necesidades específicas para optimizar el sistema, garantizando que pueda manejar la carga esperada y funcionar de manera eficiente en todos los escenarios.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium', color: Text.primary, fontSize: '16px' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: Text.primary, fontSize: '16px' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

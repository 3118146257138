import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import SitemarkIcon from './SitemarkIcon';
import { Card, CardContent, useTheme  } from '@mui/material';
import { Email, Phone, LocationOn, Language, FacebookOutlined } from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1, fontSize: '16px' }}>
      {'Derechos Reservados © '}
      <Link color="text.secondary" href="https://www.softwarefactorycr.com/">
        Software Factory CR
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

const openWhatsApp = () => {
  const phoneNumber = '+50672317420'; // Replace with the desired phone number
  window.open(`https://wa.me/${phoneNumber}`, '_blank'); // Opens WhatsApp link in a new tab
};

 // Function to open the default email client
 const openEmailClient = () => {
  const email = 'info@softwarefactorycr.com'; // Replace with the desired email address
  const subject = 'Solicitud de Información: Software Factory CR'; // Replace with the desired default subject
  window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`; // Opens default email client
};

const openWebsiteAndBookmark = () => {
  const websiteUrl = 'https://www.softwarefactorycr.com'; // Replace with your website URL
  //window.open(websiteUrl, '_blank'); // Opens the website in a new tab

  // Show instructions to bookmark the website
  setTimeout(() => {
    alert(
      'Para marcar esta página como favorita, presione Ctrl+D (Windows) o Cmd+D (Mac) en su teclado.'
    );
  }, 500); // Delay the alert slightly to allow the new tab to open first
};

const openFacebookAndBookmark = () => {
  const facebookUrl = 'https://www.facebook.com/profile.php?id=61566891327174'; // URL of the Facebook page
  
  // Opens the Facebook page in a new tab
  window.open(facebookUrl, '_blank'); 
};

export default function Footer() {
  const theme = useTheme();
  // Define colors based on the current theme mode
  const iconAndTextColor = theme.palette.mode === 'dark' ? '#fff' : '#000';

 


  return (
    <Container
      id="footer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
          <Typography
            component="h2"
            variant="h6"
            sx={{
              color: 'text.primary',
              width: { sm: '100%', md: '60%' },
              textAlign: { sm: 'left', md: 'left' },
            }}
          >
            SOFTWARE FACTORY CR
          </Typography> 
            <Typography variant="body2" gutterBottom sx={{ fontWeight: 600, mt: 2, fontSize: '16px' }}>
              
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2, fontSize: '16px' }}>
              Estaremos encantados de atender tus preguntas!
            </Typography>

            {/* contact */}
            <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)', // Transparent background
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)', // Subtle shadow for depth
        backdropFilter: 'blur(10px)', // Glassmorphism effect
        borderRadius: '16px', // Rounded corners
        padding: 2,
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-8px)', // Slight lift on hover
          boxShadow: '0px 12px 32px rgba(0, 0, 0, 0.25)', // Enhanced shadow on hover
        },
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            color: iconAndTextColor,
            textAlign: 'left',
            marginBottom: 1,
          }}
        >
          Contáctenos
        </Typography>

        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', marginBottom: 1 }}>
          <IconButton 
            aria-label="email" 
            sx={{ color: iconAndTextColor, padding: 0, cursor: 'pointer' }}
            onClick={openEmailClient}
            >
            <Email />
          </IconButton>
          <Typography sx={{ color: iconAndTextColor }}>info@softwarefactorycr.com</Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          sx={{ alignItems: 'center', marginBottom: 1, cursor: 'pointer' }}
          onClick={openWhatsApp}
        >
          <IconButton aria-label="WhatsApp" sx={{ color: iconAndTextColor, padding: 0 }}>
            <WhatsAppIcon />
          </IconButton>
          <Typography sx={{ color: iconAndTextColor }}>+506 72317420</Typography>
        </Stack>

        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <IconButton aria-label="location" sx={{ color: iconAndTextColor, padding: 0 }} onClick={openWebsiteAndBookmark}>
            <Language  />
          </IconButton>
          <Typography sx={{ color: iconAndTextColor }}>www.softwarefactorycr.com</Typography>
        </Stack>

        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', mt: 2 }}>
          <IconButton aria-label="location" sx={{ color: iconAndTextColor, padding: 0 }} onClick={openFacebookAndBookmark}>
            <FacebookOutlined  />
          </IconButton>
          <Typography sx={{ color: iconAndTextColor }}>www.facebook.com/softwarefactorycr</Typography>
        </Stack>
      </CardContent>
    </Card>
            {/* end contact */}
            
            {/* <InputLabel htmlFor="email-newsletter">Email</InputLabel> */}
            {/* <Stack direction="row" spacing={1} useFlexGap>
              <TextField
                id="email-newsletter"
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                aria-label="Enter your email address"
                placeholder="Your email address"
                slotProps={{
                  htmlInput: {
                    autoComplete: 'off',
                    'aria-label': 'Enter your email address',
                  },
                }}
                sx={{ width: '250px' }}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ flexShrink: 0 }}
              >
                Subscribe
              </Button>
            </Stack> */}
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Product
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            Features
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Testimonials
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Highlights
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Pricing
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            FAQs
          </Link>
        </Box> */}
        {/* <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Company
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            About us
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Careers
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Press
          </Link>
        </Box> */}
        {/* <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Legal
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            Terms
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Privacy
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Contact
          </Link>
        </Box> */}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>         
          <Copyright />
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: 'left', color: 'text.secondary' }}
        >        
          {/* <IconButton
            color="inherit"
            size="small"
            href="https://www.linkedin.com/company/mui/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <FacebookIcon />
          </IconButton> */}
        </Stack>
      </Box>
    </Container>
  );
}

import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    avatar: <Avatar alt="C" src="/static/images/avatar/1.jpg" />,
    name: 'Comunicación',
    occupation: 'Comunicación Abierta',
    testimonial:
      "Creemos que una comunicación efectiva es clave para alcanzar la satisfacción del cliente. Mantenemos un diálogo constante con nuestros clientes a lo largo de todo el proceso, desde la conceptualización hasta la entrega final. Esta colaboración permite ajustes y mejoras en tiempo real, asegurando que el producto final cumpla con sus requerimientos y deseos.",
  },
  {
    avatar: <Avatar alt="A" src="/static/images/avatar/2.jpg" />,
    name: 'Atención',
    occupation: 'Atención Personalizada',
    testimonial:
      "Cada cliente es único, y en Software Factory CR lo entendemos. Nos esforzamos por brindar un servicio personalizado, escuchando atentamente las inquietudes y necesidades de cada cliente. Nuestro enfoque adaptativo nos permite ofrecer soluciones específicas que se ajusten a la visión de cada negocio, asegurando que se sientan valorados y comprendidos.",
  },
  {
    avatar: <Avatar alt="E" src="/static/images/avatar/3.jpg" />,
    name: 'Evaluación ',
    occupation: 'Evaluación Continua',
    testimonial:
      'La satisfacción del cliente no termina con la entrega del proyecto. Realizamos evaluaciones continuas y solicitamos retroalimentación para mejorar nuestros servicios constantemente. En Software Factory CR, nos comprometemos a aprender y evolucionar con cada experiencia, asegurando que siempre estemos a la vanguardia de las expectativas de nuestros clientes.',
  },
  // {
  //   avatar: <Avatar alt="Reservaciones y Amenidades" src="/static/images/avatar/4.jpg" />,
  //   name: 'Reservaciones & amenidades',
  //   occupation: 'Reservaciones y Amenidades',
  //   testimonial:
  //     "Módulo de Reservaciones y Amenidades: Compuesto por dos submódulos interconectados, este sistema optimiza la gestión de reservas de amenidades en el condominio. Reservaciones permite realizar reservas tanto por día completo como por hora, adaptándose a diversas necesidades. Amenidades ofrece una interfaz gráfica interactiva que proporciona una experiencia de usuario excepcional, permitiendo a los residentes explorar y conocer en detalle cada amenidad antes de hacer una reserva, facilitando una elección informada y satisfactoria.",
  // },
  // {
  //   avatar: <Avatar alt="Mensajería Interna" src="/static/images/avatar/5.jpg" />,
  //   name: 'Mensajería Interna',
  //   occupation: 'Mensajería instantanea',
  //   testimonial:
  //     "Este módulo facilita la comunicación instantánea entre residentes, administradores y personal de seguridad mediante un sistema de mensajería con notificaciones, sonidos y alertas. Permite compartir imágenes y documentos, asegurando una gestión precisa y clara de las interacciones y eventos dentro del condominio.",
  // },
  // {
  //   avatar: <Avatar alt="Finanzas" src="/static/images/avatar/6.jpg" />,
  //   name: 'Finanzas',
  //   occupation: 'Finanzas',
  //   testimonial:
  //     "Este módulo automatiza el seguimiento y la creación de balances de ingresos y egresos, manejando transacciones tanto en colones como en dólares. Genera dinámicamente tres cuadros comparativos para facilitar la comprensión de la situación financiera en tiempo real. Accesible a todos los usuarios, proporciona una visión clara y detallada de la administración financiera del condominio.",
  // },
];

const whiteLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Buscamos la satisfacción total de cada cliente (CAE)
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.primary', fontSize: '16px' }}>
          Nuestra prioridad es garantizar la satisfacción total de cada cliente. Nos comprometemos a ofrecer productos y servicios de alta calidad que superen las expectativas. Nuestro equipo trabaja con dedicación, cuidando cada detalle en el desarrollo de soluciones personalizadas que se alineen perfectamente con las necesidades de nuestros clientes.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index} sx={{ display: 'flex' }}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: 'text.primary', fontSize: '16px' }}
                >
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />                
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

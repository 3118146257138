import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Preguntas frecuentes
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2" sx={{fontSize: '16px'}}>
            ¿Cuál es el proceso de desarrollo de software que siguen?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%', fontSize: '16px' } }}
            >
             En Software Factory CR, seguimos un enfoque ágil que incluye las etapas de planificación, diseño, desarrollo, pruebas y entrega. Mantenemos una comunicación constante con nuestros clientes en cada fase para asegurar que el proyecto se alinee con sus expectativas y requisitos.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2" sx={{fontSize: '16px'}}>
            ¿Cuánto tiempo tomará completar mi proyecto?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%', fontSize: '16px' } }}
            >
             El tiempo de entrega depende de la complejidad y alcance del proyecto. Generalmente, nuestros proyectos comienzan desde 56 horas y pueden extenderse según las funcionalidades requeridas. Proporcionamos un cronograma detallado durante la fase de planificación.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2" sx={{fontSize: '16px'}}>
            ¿Qué tecnologías utilizan para el desarrollo de software?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%', fontSize: '16px' } }}
            >
              Utilizamos una variedad de tecnologías modernas y frameworks, como React, Node.js para desarrollo web, y React Native para desarrollo móvil también bases de datos no relacionales como MongoDB y relacionales como PostgreSQL. Seleccionamos la tecnología más adecuada en función de los requisitos específicos de cada proyecto.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2" sx={{fontSize: '16px'}}>
            ¿Cómo se determinan los costos del proyecto?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%', fontSize: '16px' } }}
            >
             Nuestros precios se basan en la cantidad de horas de trabajo requeridas. Calculamos el costo multiplicando el número de días de trabajo por 8 horas diarias y el precio por hora acordado, adaptándonos a las necesidades y complejidad del proyecto.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel5d-header"
          >
            <Typography component="h3" variant="subtitle2" sx={{fontSize: '16px'}}>
            ¿Ofrecen soporte y mantenimiento después de la entrega del software?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%', fontSize: '16px' } }}
            >
              Sí, ofrecemos soporte y mantenimiento continuo después de la entrega del software. Esto incluye la corrección de errores, actualizaciones y mejoras, asegurando que el sistema se mantenga funcional y alineado con las necesidades cambiantes del cliente.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
